import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/shops',
    component: () => import('../views/Shops/ShopsList.vue')//-->lista negozi per iniziare la nuova rilevazione
  },
  {
    path: '/surveys',
    component: () => import('../views/Surveys/SurveysList.vue')//--> lista rilevazioni
  },
  {
    path: '/surveys/products',
    component: () => import('../views/Products/ProductList.vue')//--> prodotti nella rilevazione
  },
  {
    path: '/surveys/new',
    component: () => import('../views/Surveys/NewScan.vue')//--> Nuova rilevazione
  },
  {
    path: '/surveys/edit/scan',
    component: () => import('../views/Surveys/NewScan.vue')//--> nuova scannerizzazione di un prodotto per rilevazione esistente
  },
  {
    path: '/surveys/:id_rilevazione/:product_ean/edit',//Passando da lista prodotti, modifica articolo
    component: () => import('../views/Products/EditProduct.vue')
  },
  {
    path: '/surveys/new/product',
    component: () => import('../views/Products/NewProduct.vue')//-->form nuovo prodotto nuova rilevazione
  },
  {
    path: '/surveys/edit/product',
    component: () => import('../views/Products/NewProductScan.vue')//-->solo nuovo prodotto, rilevazione esistente
  },
  {
    name: "login",
    path: '/login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach(async (to) => {
  if (!store.getters['auth/isLogged'] && to.path !== '/login') {
    router.push("/login")
  }
})

export default router
