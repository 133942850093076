/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { toastController} from "@ionic/vue";

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.VUE_APP_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    async updated () {
      const toast = await toastController.create({
        message: 'Nuova versione disponibile!',
        duration: 10000,
        position: 'top',
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          }
        ]
      });

      await toast.present();
      console.log('New content is available; please refresh.')
    },
    async offline () {
      const toast = await toastController.create({
        message: 'Nessuna connessione trovata. L\'app  è in modalità offline.',
        duration: 10000,
        position: 'top',
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          }
        ]
      });

      await toast.present();
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
