import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, { "content-id": "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            disabled: !_ctx.isLogged,
            "content-id": "main-content",
            type: "overlay"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, { id: "inbox-list" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, { class: "ion-margin-bottom" }, {
                        default: _withCtx(() => [
                          _createTextVNode("UniCoop")
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (p, i) => {
                        return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                          "auto-hide": "false",
                          key: i
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, {
                              onClick: ($event: any) => (_ctx.selectedIndex = i),
                              "router-direction": "root",
                              "router-link": p.url,
                              lines: "none",
                              detail: "false",
                              class: _normalizeClass(["hydrated", { selected: _ctx.selectedIndex === i }])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  color: "primary",
                                  slot: "start",
                                  ios: p.iosIcon,
                                  md: p.mdIcon
                                }, null, 8, ["ios", "md"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(p.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick", "router-link", "class"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled"]),
          (_ctx.isLogged)
            ? (_openBlock(), _createBlock(_component_ion_header, {
                key: 0,
                translucent: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, { class: "topBar" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, {
                        slot: "start",
                        style: {"display":"flex"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_menu_button, { color: "light" }),
                          _createVNode(_component_ion_title, {
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                color: "tertiary",
                                icon: _ctx.homeOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_router_outlet, { id: "main-content" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}