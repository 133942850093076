import ApiFactory from '../factories/apiFactory';
import router from '@/router';
import store from '@/store';

interface token {
    refreshToken: string,
    accessToken: string,
    idToken: string,
}

const TOKEN_URL = `${process.env.VUE_APP_BASE_URL}/oauth/token`;
const REFRESH_TOKEN_URL = `${process.env.VUE_APP_BASE_URL}/oauth/refreshtoken`;
const LOGOUT_URL = `${process.env.VUE_APP_BASE_URL}/v1/logout`;

const requestConfiguration = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'client-key': process.env.VUE_APP_CLIENT_KEY,
        
    }
};
const refreshConfiguration = {
    headers: {
        'client-key': process.env.VUE_APP_CLIENT_KEY,
    },
}

const authorization = {
    login(credentials: any) {
        return new Promise((resolve, reject) =>
            ApiFactory.post(TOKEN_URL, credentials, requestConfiguration)
                .then(
                    (success) => {
                        resolve(success.data);
                        store.commit('auth/setToken', success.data);
                        store.commit('auth/setIsLogged', true);
                        router.push("/")
                    },
                    (error) => reject(error))
        );
    },
    logout() {
        return new Promise((resolve, reject) =>
            ApiFactory.post(LOGOUT_URL, {}, requestConfiguration)
                .then(
                    (success) => { resolve(success.data); store.commit('auth/setIsLogged', false) },
                    (error) => reject(error))
        );
    },
    refreshtoken(credentials: any): Promise<token> {
        return new Promise((resolve, reject) =>
            ApiFactory.post(REFRESH_TOKEN_URL, { "refresh_token": credentials }, refreshConfiguration)
                .then(
                    (success) => {
                        resolve(success.data);
                        console.log(success.data)
                        store.commit('auth/setToken', success.data);
                        router.push("/")
                    },
                    (error) => reject(error))
        );
    }
};

export default authorization;
