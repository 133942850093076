import { createStore } from 'vuex';
import surveys from '@/store/modules/surveys';
import auth from '@/store/modules/auth';
import VuexPersistence from 'vuex-persist';

const debug = process.env.NODE_ENV !== 'production'
const vuexLocalStorage = new VuexPersistence({
    key: 'unicoop-app-concorrenza',
    storage: window.localStorage
})

export default createStore({
    plugins: [vuexLocalStorage.plugin],
    modules: {
        surveys,
        auth
    },
    strict: false
})
