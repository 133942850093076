
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonMenuButton
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { barcodeOutline, listOutline, homeOutline } from "ionicons/icons";
import store from "./store";
//import authorization from "./services/authorization";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonMenuButton,
  },
  computed: {
    isLogged() {
      return store.getters["auth/isLogged"];
    },
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "Nuova Rilevazione",
        url: "/shops",
        iosIcon: barcodeOutline,
        mdIcon: barcodeOutline,
      },
      {
        title: "Lista Rilevazioni",
        url: "/surveys",
        iosIcon: listOutline,
        mdIcon: listOutline,
      },
    ];
    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    const route = useRoute();

    return {
      homeOutline,
      selectedIndex,
      appPages,
      listOutline,
      barcodeOutline,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
});
